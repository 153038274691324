<script>
export default {
  name: 'Home',
  mounted() {
    window.addEventListener('wheel', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      const scrollHeight = window.scrollY;
      const windowHeight = window.innerHeight;
      let targetScroll;

      if (e.deltaY < 0) {
        targetScroll = Math.floor(scrollHeight / windowHeight) * windowHeight;
      }

      else {
        targetScroll = Math.ceil(scrollHeight / windowHeight) * windowHeight;
      }

      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  }
};
</script>

<template>
  <div id="outer-most">
    <div class="page_card" id="page1">
      <div id="main-title">My Playground</div>
    </div>
    <div class="page_card" id="page2">

      <div class="start-button">start this page</div>
    </div>
    <div class="page_card">
      <p>3</p>
    </div>
    <div class="page_card">
      <p>4</p>
    </div>
    <div class="page_card">
      <p>5</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
  }

  body, html {
    height: auto !important;
  }

  #outer-most {
    width: 100%;
    height: 500vh;
    background: rgb(30, 30, 30);
    display: flex;
    flex-direction: column;
  }

  .page_card {
    height: 100vh;
    width: 100%;
  }

  #page1 {
    position: relative;
    background-image: url("https://gitlab.com/zjq8947/imagebed/-/raw/main/pictures/2024/10/23_11_5_54_lotus_background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
  }

  .start-button {
    position: absolute;
    width: 200px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    line-height: 48px;
    font-size: 25px;
    background: rgba(255, 255, 255, 0.4);
    margin: auto;
    bottom: 12rem;
    right: 12rem;
  }

  #main-title {
    line-height: 100vh;
    font-size: 10rem;
    text-align: center;
  }

  #page2 {
    position: relative;
    background-image: url("https://gitlab.com/zjq8947/imagebed/-/raw/main/pictures/2024/10/23_11_5_54_lotus_background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

</style>